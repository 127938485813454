import { tuiCreateTokenFromFactory } from '@taiga-ui/cdk';
import { tuiExtractI18n } from '@taiga-ui/i18n';

/**
 * tui-reorder i18n button
 */
const TUI_TABLE_SHOW_HIDE_MESSAGE = tuiCreateTokenFromFactory(tuiExtractI18n('showHideText'));
/**
 * tui-table-pagination i18n texts
 */
const TUI_TABLE_PAGINATION_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('paginationTexts'));

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_TABLE_PAGINATION_TEXTS, TUI_TABLE_SHOW_HIDE_MESSAGE };

