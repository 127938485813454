import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Inject, Input, Output, NgModule } from '@angular/core';
import { TUI_TABLE_PAGINATION_TEXTS } from '@taiga-ui/addon-table/tokens';
import * as i1 from '@taiga-ui/core';
import { TUI_SPIN_ICONS, TUI_SPIN_TEXTS, TUI_COMMON_ICONS, TuiButtonModule, TuiLinkModule, TuiHostedDropdownModule, TuiDataListModule, TuiSvgModule } from '@taiga-ui/core';
import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i4 from 'rxjs';
const _c0 = (a0, a1) => ({
  $implicit: a0,
  total: a1
});
function TuiTablePaginationComponent_ng_container_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementStart(2, "strong", 8);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const texts_r2 = i0.ɵɵnextContext().ngIf;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", texts_r2.pages, " ");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.pages);
  }
}
function TuiTablePaginationComponent_ng_container_0_button_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 9)(1, "strong");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2("", ctx_r2.start + 1, "\u2013", ctx_r2.end, "");
  }
}
function TuiTablePaginationComponent_ng_container_0_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "strong");
    i0.ɵɵtext(1, "0 - 0");
    i0.ɵɵelementEnd();
  }
}
function TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r6 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r6, " ");
  }
}
function TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_tui_svg_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 15);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("src", ctx_r2.commonIcons.check);
  }
}
function TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 16);
  }
}
function TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 12);
    i0.ɵɵlistener("click", function TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_Template_button_click_1_listener() {
      const item_r5 = i0.ɵɵrestoreView(_r4).$implicit;
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.onItem(item_r5));
    });
    i0.ɵɵtemplate(2, TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_ng_container_2_Template, 2, 1, "ng-container", 13)(3, TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_tui_svg_3_Template, 1, 1, "tui-svg", 14)(4, TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_ng_template_4_Template, 1, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r5 = ctx.$implicit;
    const fakeIcon_r7 = i0.ɵɵreference(5);
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.options.sizeOptionContent)("polymorpheusOutletContext", i0.ɵɵpureFunction2(4, _c0, item_r5, ctx_r2.total));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r5 === ctx_r2.size)("ngIfElse", fakeIcon_r7);
  }
}
function TuiTablePaginationComponent_ng_container_0_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tui-data-list", 10);
    i0.ɵɵtemplate(1, TuiTablePaginationComponent_ng_container_0_ng_template_9_ng_container_1_Template, 6, 7, "ng-container", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.items);
  }
}
function TuiTablePaginationComponent_ng_container_0_ng_container_14_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 17);
    i0.ɵɵlistener("click", function TuiTablePaginationComponent_ng_container_0_ng_container_14_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.back());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 18);
    i0.ɵɵlistener("click", function TuiTablePaginationComponent_ng_container_0_ng_container_14_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.forth());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const spinTexts_r9 = ctx.ngIf;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.leftDisabled)("icon", ctx_r2.icons.decrement)("title", spinTexts_r9[0]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.rightDisabled)("icon", ctx_r2.icons.increment)("title", spinTexts_r9[1]);
  }
}
function TuiTablePaginationComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 4);
    i0.ɵɵtemplate(2, TuiTablePaginationComponent_ng_container_0_ng_container_2_Template, 4, 2, "ng-container", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 5);
    i0.ɵɵtext(4);
    i0.ɵɵelementStart(5, "tui-hosted-dropdown", 6);
    i0.ɵɵtwoWayListener("openChange", function TuiTablePaginationComponent_ng_container_0_Template_tui_hosted_dropdown_openChange_5_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r2.open, $event) || (ctx_r2.open = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵtemplate(6, TuiTablePaginationComponent_ng_container_0_button_6_Template, 3, 2, "button", 7)(7, TuiTablePaginationComponent_ng_container_0_ng_template_7_Template, 2, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(9, TuiTablePaginationComponent_ng_container_0_ng_template_9_Template, 2, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(11);
    i0.ɵɵelementStart(12, "strong", 8);
    i0.ɵɵtext(13);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(14, TuiTablePaginationComponent_ng_container_0_ng_container_14_Template, 3, 6, "ng-container", 3);
    i0.ɵɵpipe(15, "async");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const texts_r2 = ctx.ngIf;
    const zeroTotal_r10 = i0.ɵɵreference(8);
    const content_r11 = i0.ɵɵreference(10);
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r2.options.showPages);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", texts_r2.linesPerPage, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("content", content_r11);
    i0.ɵɵtwoWayProperty("open", ctx_r2.open);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.total !== 0)("ngIfElse", zeroTotal_r10);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", texts_r2.of, " ");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.total);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(15, 9, ctx_r2.spinTexts$));
  }
}
function defaultSizeOptionContent({
  $implicit
}) {
  return `${$implicit}`;
}
const TUI_TABLE_PAGINATION_DEFAULT_OPTIONS = {
  sizeOptionContent: defaultSizeOptionContent,
  showPages: true,
  items: [10, 20, 50, 100],
  size: 10
};
/**
 * Default parameters for TablePagination component
 */
const TUI_TABLE_PAGINATION_OPTIONS = tuiCreateToken(TUI_TABLE_PAGINATION_DEFAULT_OPTIONS);
function tuiTablePaginationOptionsProvider(options) {
  return tuiProvideOptions(TUI_TABLE_PAGINATION_OPTIONS, options, TUI_TABLE_PAGINATION_DEFAULT_OPTIONS);
}
class TuiTablePaginationComponent {
  constructor(icons, spinTexts$, texts$, options, commonIcons) {
    this.icons = icons;
    this.spinTexts$ = spinTexts$;
    this.texts$ = texts$;
    this.options = options;
    this.commonIcons = commonIcons;
    this.items = this.options.items;
    this.total = 0;
    this.page = 0;
    this.size = this.options.size;
    /**
     * TODO: Remove in 4.0
     * @deprecated use paginationChange
     */
    this.pageChange = new EventEmitter();
    /**
     * TODO: Remove in 4.0
     * @deprecated use paginationChange
     */
    this.sizeChange = new EventEmitter();
    this.paginationChange = new EventEmitter();
    this.open = false;
  }
  get pages() {
    return Math.ceil(this.total / this.size);
  }
  get start() {
    return this.page * this.size;
  }
  get end() {
    return Math.min(this.start + this.size, this.total);
  }
  get leftDisabled() {
    return !this.start;
  }
  get rightDisabled() {
    return this.end === this.total;
  }
  get pagination() {
    return {
      page: this.page,
      size: this.size
    };
  }
  onItem(size) {
    const {
      start
    } = this;
    this.size = size;
    this.sizeChange.emit(size);
    this.open = false;
    this.page = Math.floor(start / this.size);
    this.pageChange.emit(this.page);
    this.paginationChange.emit(this.pagination);
  }
  back() {
    this.page--;
    this.pageChange.emit(this.page);
    this.paginationChange.emit(this.pagination);
  }
  forth() {
    this.page++;
    this.pageChange.emit(this.page);
    this.paginationChange.emit(this.pagination);
  }
}
TuiTablePaginationComponent.ɵfac = function TuiTablePaginationComponent_Factory(t) {
  return new (t || TuiTablePaginationComponent)(i0.ɵɵdirectiveInject(TUI_SPIN_ICONS), i0.ɵɵdirectiveInject(TUI_SPIN_TEXTS), i0.ɵɵdirectiveInject(TUI_TABLE_PAGINATION_TEXTS), i0.ɵɵdirectiveInject(TUI_TABLE_PAGINATION_OPTIONS), i0.ɵɵdirectiveInject(TUI_COMMON_ICONS));
};
TuiTablePaginationComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiTablePaginationComponent,
  selectors: [["tui-table-pagination"]],
  inputs: {
    items: "items",
    total: "total",
    page: "page",
    size: "size"
  },
  outputs: {
    pageChange: "pageChange",
    sizeChange: "sizeChange",
    paginationChange: "paginationChange"
  },
  decls: 2,
  vars: 3,
  consts: [["zeroTotal", ""], ["content", ""], ["fakeIcon", ""], [4, "ngIf"], [1, "t-pages"], ["automation-id", "tui-table-pagination__lines-per-page-wrapper"], [3, "openChange", "content", "open"], ["tuiLink", "", "type", "button", 4, "ngIf", "ngIfElse"], [1, "t-strong"], ["tuiLink", "", "type", "button"], ["size", "s"], [4, "ngFor", "ngForOf"], ["tuiOption", "", 1, "t-item", 3, "click"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["class", "t-checkmark", 3, "src", 4, "ngIf", "ngIfElse"], [1, "t-checkmark", 3, "src"], [1, "t-checkmark"], ["appearance", "icon", "size", "xs", "tuiIconButton", "", "type", "button", 1, "t-back", 3, "click", "disabled", "icon", "title"], ["appearance", "icon", "size", "xs", "tuiIconButton", "", "type", "button", 3, "click", "disabled", "icon", "title"]],
  template: function TuiTablePaginationComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiTablePaginationComponent_ng_container_0_Template, 16, 11, "ng-container", 3);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.texts$));
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiLinkComponent, i1.TuiDataListComponent, i1.TuiOptionComponent, i1.TuiSvgComponent, i1.TuiButtonComponent, i2.NgIf, i2.NgForOf, i3.PolymorpheusOutletDirective, i2.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:flex;font:var(--tui-font-text-s);align-items:center;color:var(--tui-text-03)}.t-strong[_ngcontent-%COMP%]{color:var(--tui-text-01)}.t-pages[_ngcontent-%COMP%]{margin-right:auto}.t-item[_ngcontent-%COMP%]{min-width:5.5rem;box-sizing:border-box}.t-checkmark[_ngcontent-%COMP%]{min-width:1.5rem;border-left:5px solid transparent}.t-back[_ngcontent-%COMP%]{margin:0 .25rem 0 1.5rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTablePaginationComponent, [{
    type: Component,
    args: [{
      selector: 'tui-table-pagination',
      templateUrl: './table-pagination.template.html',
      styleUrls: ['./table-pagination.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_SPIN_ICONS]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_SPIN_TEXTS]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_TABLE_PAGINATION_TEXTS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_TABLE_PAGINATION_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_COMMON_ICONS]
      }]
    }];
  }, {
    items: [{
      type: Input
    }],
    total: [{
      type: Input
    }],
    page: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    pageChange: [{
      type: Output
    }],
    sizeChange: [{
      type: Output
    }],
    paginationChange: [{
      type: Output
    }]
  });
})();
class TuiTablePaginationModule {}
TuiTablePaginationModule.ɵfac = function TuiTablePaginationModule_Factory(t) {
  return new (t || TuiTablePaginationModule)();
};
TuiTablePaginationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiTablePaginationModule
});
TuiTablePaginationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiButtonModule, TuiLinkModule, TuiHostedDropdownModule, TuiDataListModule, TuiSvgModule, PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTablePaginationModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiButtonModule, TuiLinkModule, TuiHostedDropdownModule, TuiDataListModule, TuiSvgModule, PolymorpheusModule],
      declarations: [TuiTablePaginationComponent],
      exports: [TuiTablePaginationComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_TABLE_PAGINATION_DEFAULT_OPTIONS, TUI_TABLE_PAGINATION_OPTIONS, TuiTablePaginationComponent, TuiTablePaginationModule, tuiTablePaginationOptionsProvider };
